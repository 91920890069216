import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import AuthService from "@/services/AuthService";
import store from "@/store";
import Swal from "sweetalert2";
import { i18n } from "@/helpers/i18nSet";
import { router } from "@/router";

//const storedUser = localStorage.getItem("user");

@Module({ namespaced: true, store })
class User extends VuexModule {
  /*public status = storedUser.loggedIn
        ? { loggedIn: true }
        : { loggedIn: false };
       public user = storedData.auth.user !== {} ? storedData.auth.user : null;*/

  public status = { loggedIn: false };
  public user = {};

  get isLoggedIn(): boolean {
    return this.status.loggedIn;
  }

  @Mutation
  public loginSuccess(user: any): void {
    this.status.loggedIn = true;
    this.user = user;
  }

  @Mutation
  public loginFailure(): void {
    this.status.loggedIn = false;
    this.user = {};
  }

  @Mutation
  public logout(): void {
    this.status.loggedIn = false;
    this.user = {};
  }

  @Mutation
  public registerSuccess(): void {
    this.status.loggedIn = false;
  }

  @Mutation
  public registerFailure(): void {
    this.status.loggedIn = false;
  }

  /*
   *   store.commit("auth/updateUser", {
   *      key: value,
   *      language: response?.data.data.locale
   *   });
   *
   * */
  @Mutation
  public updateUser(UserObj: object): void {
    for (const property in UserObj) {
      this.user["user"][property] = UserObj[property];
    }
  }

  @Action({ rawError: true })
  login(data: any): Promise<any> {
    return AuthService.login(data.email, data.password).then(
      (user) => {
        this.context.commit("loginSuccess", user);
        return Promise.resolve(user);
      },
      (error) => {
        this.context.commit("loginFailure");
        /*const message =
                                        (error.response &&
                                          error.response.data &&
                                         error.response.data.message) ||
                                          error.message ||
                                         error.toString();*/

        if ("migrated" in error.response.data.errors && error.response.data.errors.migrated === false) {
          Swal.fire({
            title: i18n.global.t("common.note_system"),
            text: error.response.data.message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: i18n.global.t("buttons.begin_migration"),
          }).then((result) => {
            if (result.isConfirmed) {
              router.push("/migration");
            }
          });
        } else {
          this.context.dispatch(
            "notifications/addNotification",
            {
              type: "error",
              //body: { type: 200, message: "aa" },
              body: error,
            },
            {
              root: true,
            }
          );
        }
        return Promise.reject(error);
      }
    );
  }

  /* @Action({ rawError: true })
    register(data: any): Promise<any> {
      return AuthService.register(data.username, data.email, data.password).then(
        (response) => {
          this.context.commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          this.context.commit("registerFailure");
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          return Promise.reject(message);
        }
      );
    }*/

  @Action({ rawError: true })
  async signOut(root: boolean): Promise<void> {
    const result = AuthService.logout();
    this.context.commit("logout");
    await this.context.dispatch(
      "notifications/addNotification",
      {
        type: "success",
        body: { type: 200, message: result },
      },
      {
        root: typeof root === "undefined" ? true : root,
      }
    );
  }

  @Action({ rawError: true })
  forgotPassword(email: string): Promise<any> {
    return AuthService.forgotPassword(email).then(
      (data) => {
        return Promise.resolve(data);
      },
      (error) => {
        this.context.dispatch(
          "notifications/addNotification",
          {
            type: "error",
            body: error,
          },
          {
            root: true,
          }
        );
        return Promise.reject(error);
      }
    );
  }

  @Action({ rawError: true })
  resetPassword(data: any): any /*Promise<any>*/ {
    return AuthService.resetPassword(data).then(
      (data) => {
        return Promise.resolve(data);
      },
      (error) => {
        this.context.dispatch(
          "notifications/addNotification",
          {
            type: "error",
            body: error,
          },
          {
            root: true,
          }
        );
        return Promise.reject(error);
      }
    );
  }
}

export default User;
