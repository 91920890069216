import { RouteRecordRaw } from "vue-router";
import { RolePermType } from "@/store/enums/AuthEnums";

export const User: Array<RouteRecordRaw> = [
  {
    path: "/users",
    name: "user-management",
    component: () => import("@/views/user/user-management/UserManagement.vue"),
    meta: {
      resources: ["User"],
      permissions: [RolePermType.SUPERADMIN, RolePermType.ADMIN],
    },
    children: [
      {
        path: "",
        name: "user-management-index",
        component: () => import("@/views/user/user-management/UserManagementIndex.vue"),
        meta: { resources: ["User"] },
      },
      {
        path: "/user-management/create",
        name: "user-management-create",
        component: () => import("@/views/user/user-management/UserManagementCreate.vue"),
        meta: { resources: ["User"] },
      },
      {
        path: "/user-management/edit/:id",
        name: "user-management-edit",
        component: () => import("@/views/user/user-management/UserManagementEdit.vue"),
        meta: { resources: ["User"] },
      },
      {
        path: "/user-management/delete/:id",
        name: "user-management-delete",
        component: () => import("@/views/user/user-management/UserManagementDelete.vue"),
        meta: { resources: ["User"] },
      },
      {
        path: "/user-management/detail/:id/merchants",
        name: "user-management-show-merchants",
        component: () => import("@/views/user/user-management/UserManagementShowMerchants.vue"),
        meta: { resources: ["User"] },
      },
    ],
  },
  {
    path: "/my-profile",
    name: "my-profile-index",
    component: () => import("@/views/user/my-profile/MyProfileIndex.vue"),
    meta: { resources: ["User"] },
  },
  {
    path: "/my-profile/merchants",
    name: "my-profile-show-merchants",
    component: () => import("@/views/user/user-management/UserManagementShowMerchants.vue"),
    meta: { resources: ["User"] },
  },
  {
    path: "/starting-tips",
    name: "starting-tips",
    component: () => import("@/views/page/merchant/StartingTips.vue"),
    meta: { resources: ["Merchant"] },
  },
];
export default User;
